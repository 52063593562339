(function ($) {
  Drupal.behaviors.contentBlockStoryV1 = {
    attach: function (context) {
      var applyOpacity = function () {
        var size = Unison.fetch.now().name;
        var e = $('.js-opaque-bg');
        var currentColor = $(e).css('background-color');
        var newColor = '';

        if (typeof currentColor !== 'undefined') {
          if (size === 'small') {
            newColor = currentColor.replace(', 0.75)', ', 0.99)');
          } else {
            newColor = currentColor.replace(', 0.99)', ', 0.75)');
          }
          $(e).css('background-color', newColor);
        }
      };

      applyOpacity();
      Unison.on('change', function (bp) {
        applyOpacity();
      });
    }
  };
})(jQuery);
